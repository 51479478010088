<template>
	<Disclosure class="ClientStatus" title="Status" :expanded="true" v-if="client">
		<div v-for="(salesChannel, sc) of client.fields.salesChannels.de" :key="sc" class="salesChannel">
			<h3>{{ salesChannel.fields.title?.de }} (code: {{ salesChannel.fields.code?.de }})</h3>
			<div v-for="(topic, t) of topicsForSalesChannel(salesChannel)" :key="t">
				<a target="_blank" :href="url + topic">{{ topic }}</a>
			</div>
		</div>
	</Disclosure>
</template>

<script>
import Disclosure from '../../../components/common/Disclosure.vue'

export default {
	components: { Disclosure },
	props: {
		client: Object,
	},
	computed: {
		url() {
			// TODO: staging or prod?
			return 'https://kafka-ui.production.anexia.skiline.cc/ui/clusters/production.anexia/topics/'
		},
	},
	methods: {
		topicsForSalesChannel(salesChannel) {
			const clientTopics = [ ...(this.client.fields.kafkaTopics?.de ?? []) ]
			// TODO: is this needed?
			//if (this.client.fields.kafkaTopic?.de)
			//	clientTopics.push(this.client.fields.kafkaTopic?.de)
			const r = []
			for (const clientTopic of clientTopics) {
				if (!salesChannel.fields.code?.de) continue
				r.push('ps.' + clientTopic + '.' + salesChannel.fields.code.de + '.customer')
			}
			return r
		},
	},
}
</script>

<style scoped>
</style>