<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		
		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>{{ this.client.fields.title.de }} Detail View</h2>
				</v-row>
			</div>
		</div>

		<SideBar>
			<div class="sidebar-block" v-if="userMayEditFields()">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn blue mt-3" elevation="0" dark @click="upsertClient()">Save Changes</v-btn>
				</div>
			</div>

			<div class="sidebar-block" v-if="action!==Action.CREATE">
				<p class="sidebar-title">Info</p>
				id: <v-label v-if="client?.sys">{{ client.sys.id }}</v-label>
				<br />
				clientId: <v-label v-if="client?.fields?.clientId">{{ client.fields.clientId.de }}</v-label>
			</div>
		</SideBar>

		<!-- General Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="General Information" :expanded="false" :error="sectionMessage.generalInfo.error" :message="sectionMessage.generalInfo.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Client Name <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						:hide-details="!clientNameError.length"
						v-model="client.fields.title.de"
						:error-messages="clientNameError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Client ID <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						:hide-details="!clientIdError.length"
						v-model="client.fields.clientId.de"
						:error-messages="clientIdError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Logo URL</v-label>
					<v-text-field outlined required hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.logoUrl.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Currency <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						:hide-details="!currencyError.length"
						v-model="client.fields.currency.de"
						:error-messages="currencyError"
					/>
				</div>

				<div class="field left-border">
					<v-row style="padding:10px">
						<v-label>Background Image</v-label>
						<v-spacer/>
						<v-btn v-if="isUpdateBackgroundImage" class="btn" elevation="0" dense @click="cancelUpload()">Cancel Upload</v-btn>
					</v-row>

					<MediaCard 
						v-if="!isUpdateBackgroundImage && client.fields.backgroundImage.de.fields"
						:media="client.fields.backgroundImage.de" 
						:canEdit="userMayEditFields()" 
						:canSelect="false"
						:showTitle="false"
						:fullView="true" 
						@show-media-update="showUpdateBackgroundImage()"
					/>
					<br/>
					<FileUpload 
						v-if="isUpdateBackgroundImage || !client.fields.backgroundImage.de.fields"
						:options="backgroundImageDropOptions"
						ref="fileUpload"
						@thumbnail-created="onThumbnailCreated"
						@file-removed="cancelUpload"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- Features -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Features" :expanded="false" :error="sectionMessage.features.error" :message="sectionMessage.features.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Client Features</v-label>
					<v-row v-for="feature in clientFeatures" :key="feature.id" class="pa-3">
						<v-switch hide-details 
							:disabled="!userMayEditFields()"
							v-model="feature.selected"
							:label="feature.id" 
						/>
					</v-row>
				</div>

				<div class="field left-border">
					<v-label>Marketplace Settings</v-label>
					<v-row v-for="setting in marketplaceSettings" :key="setting.id" class="pa-3">
						<v-switch hide-details 
							:disabled="!userMayEditFields()"
							v-model="setting.enabled"
							:label="setting.id" 
						/>
					</v-row>
				</div>
			</Disclosure>
		</div>

		<!-- PEAK Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="PEAK Config" :expanded="false" :error="sectionMessage.peakConfig.error" :message="sectionMessage.peakConfig.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Installation Id (SKISUP) <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						:hide-details="!installationIdError.length"
						v-model="client.fields.installationId.de"
						:error-messages="installationIdError"
					/>
				</div>

				<div class="field left-border">
					<v-label>MyServices Config Store ID</v-label>
					<v-text-field outlined required hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.configStoreId.de"
					/>
				</div>
				
				<div class="field left-border">
					<v-label>Shop Client ID <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						:hide-details="!shopClientIdError.length"
						v-model="client.fields.shopClientId.de"
						:error-messages="shopClientIdError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Shop URL <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						:hide-details="!shopUrlError.length"
						v-model="client.fields.shopUrl.de"
						:error-messages="shopUrlError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Api User</v-label>
					<v-text-field outlined required hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.peakApiUser.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Api User Password</v-label>
					<v-text-field outlined required hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.peakApiUserPassword.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Xmlrpc User</v-label>
					<v-text-field outlined required hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.xmlrpcUser.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Xmlrpc User Password</v-label>
					<v-text-field outlined required hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.xmlrpcPassword.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Billing Domain Id (PS3)</v-label>
					<v-text-field outlined required hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.billingDomainId.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Kafka Topic (mandant only)</v-label>
					<v-combobox
						chips multiple outlined dense append-icon="" 
						:disabled="!userMayEditFields()"
						hide-details
						v-model="client.fields.kafkaTopics.de">
						<template v-slot:selection="{ attrs, item, select, selected }">
						<v-chip
							v-bind="attrs"
							:input-value="selected"
							close
							@click="select"
							@click:close="removeKafkaTopic(item)">
							{{ item }}
						</v-chip>
						</template>
					</v-combobox>
				</div>
			</Disclosure>
		</div>

		<!-- Contentful Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Contentful Config" :expanded="false" :error="sectionMessage.contentfulConfig.error" :message="sectionMessage.contentfulConfig.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Content-Hub Space</v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						hide-details
						v-model="client.fields.contentfulSpace.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Content-Hub Environment</v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						hide-details
						v-model="client.fields.contentfulEnvironment.de"
					/>
				</div>
			
			</Disclosure>
		</div>

		<!-- Contingent Service Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Contingent Service Config" :expanded="false" :error="sectionMessage.contingentServiceConfig.error" :message="sectionMessage.contingentServiceConfig.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Contingent Service URL <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						:hide-details="!contingentServiceUrlError.length"
						v-model="client.fields.contingentServiceUrl.de"
						:error-messages="contingentServiceUrlError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Contingent Service User <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						:hide-details="!contingentServiceUserError.length"
						v-model="client.fields.contingentServiceUser.de"
						:error-messages="contingentServiceUserError"
					/>
				</div>
				
				<div class="field left-border">
					<v-label>Contingent Service Password <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						:hide-details="!contingentServicePasswordError.length"
						v-model="client.fields.contingentServicePassword.de"
						:error-messages="contingentServicePasswordError"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- Ticket Service Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Ticket Service Config" :expanded="false" :error="sectionMessage.ticketServiceConfig.error" :message="sectionMessage.ticketServiceConfig.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Ticket Service URL</v-label>
					<v-text-field outlined hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.ticketServiceUrl.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Ticket Service User</v-label>
					<v-text-field outlined hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.ticketServiceUser.de"
					/>
				</div>
				
				<div class="field left-border">
					<v-label>Ticket Service Password</v-label>
					<v-text-field outlined hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.ticketServicePassword.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Ticket Service Tenant</v-label>
					<v-text-field outlined hide-details 
						:disabled="!userMayEditFields()"
						v-model="client.fields.ticketServiceTenant.de"
					/>
				</div>

				<div class="field left-border">
					<v-switch 
						:disabled="!userMayEditFields()"
						v-model="client.fields.isTicketServiceProxy.de"
						label="Client uses the Proxy Ticket Service" 
					/>
				</div>
			</Disclosure>
		</div>

		<!-- James Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="James Config" :expanded="false" :error="sectionMessage.jamesConfig.error" :message="sectionMessage.jamesConfig.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>JAMES Customer ID <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:disabled="!userMayEditFields()"
						hide-details
						v-model="client.fields.jamesCustomerId.de"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- Sales Channels -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Sales Channels" :expanded="false" :error="sectionMessage.salesChannels.error" :message="sectionMessage.salesChannels.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px;width:100%" 
						:value="salesChannels" :max="20" 
						:def="schema.contentTypes.client.fields.find(field => field.id === 'salesChannels')" 
						:filter="salesChannelFilter"
						:userMayEditFields="userMayEditFields()"
						@close="editorPop($event)"
						@subedit="editorPush($event)"
						@subcreate="editorPushNew($event)"/>
				</div>
			</Disclosure>
		</div>

		<!-- Regions -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Regions" :expanded="false" :error="sectionMessage.salesChannels.error" :message="sectionMessage.salesChannels.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px;width:100%" 
						:value="regions" :max="20" 
						:def="schema.contentTypes.client.fields.find(field => field.id === 'regions')" 
						:filter="regionsFilter"
						:userMayEditFields="userMayEditFields()"
						@close="editorPop($event)"
						@subedit="editorPush($event)"
						@subcreate="editorPushNew($event)"/>
				</div>
			</Disclosure>
		</div>

		<!-- Product Categories -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Product Categories" :expanded="false" :error="sectionMessage.productCategories.error" :message="sectionMessage.productCategories.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px" 
						:value="productCategories" 
						:def="schema.contentTypes.client.fields.find(field => field.id === 'productCategories')" 
						:filter="productCategoryFilter"
						:userMayEditFields="userMayEditFields()"
						@close="editorPop($event)"
						@subedit="editorPush($event)"
						@subcreate="editorPushNew($event)"/>
				</div>
			</Disclosure>
		</div>

		<!-- Tax Classes -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Tax Classes" :expanded="false" :error="sectionMessage.taxClasses.error" :message="sectionMessage.taxClasses.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px" 
						:value="taxClasses" :max="20" 
						:def="schema.contentTypes.client.fields.find(field => field.id === 'taxClasses')" 
						:filter="taxClassFilter"
						:userMayEditFields="userMayEditFields()"
						@close="editorPop($event)"
						@subedit="editorPush($event)"
						@subcreate="editorPushNew($event)"/>
				</div>
			</Disclosure>
		</div>

		<!-- Ticket Types -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Ticket Types" :expanded="false" :error="sectionMessage.ticketTypes.error" :message="sectionMessage.ticketTypes.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px" 
						:value="ticketTypes" :max="20" 
						:def="schema.contentTypes.client.fields.find(field => field.id === 'ticketTypes')" 
						:filter="ticketTypeFilter"
						:userMayEditFields="userMayEditFields()"
						@close="editorPop($event)"
						@subedit="editorPush($event)"
						@subcreate="editorPushNew($event)"/>
				</div>
			</Disclosure>
		</div>

		<!-- Reductions -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Reductions" :expanded="false" :error="sectionMessage.reductions.error" :message="sectionMessage.reductions.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px" 
						:value="reductions" :max="20" 
						:def="schema.contentTypes.client.fields.find(field => field.id === 'reductions')" 
						:filter="reductionFilter"
						:userMayEditFields="userMayEditFields()"
						@close="editorPop($event)"
						@subedit="editorPush($event)"
						@subcreate="editorPushNew($event)"/>
				</div>
			</Disclosure>
		</div>

		<!-- Status -->
		<div v-if="action!==Action.CREATE" class="pa-3" style="width: 100%">
			<ClientStatus :client="client" />
		</div>

		<!-- Change Log -->
		<div v-if="action!==Action.CREATE" class="pa-3" style="width: 100%">
			<AuditLog :entryId="clientId" :contentType="'client'"/>
		</div>

		<Dialog ref="entryEditorDialog"
			:title="editorTitle"
			:showClose="false"
			:confirmLabel="userMayEditFields ? 'Save Changes' : null"
			cancelLabel="Cancel"
			:backLabel="editorStack.length > 1 ? 'Back' : null"
			:backHandler="editorBackHandler"
			:confirm-handler="userMayEditFields ? confirmHandler  : null"
			:cancel-handler="cancelHandler"
			:height="'90%'"
			:width="'800px'">
			<template #content>
				<div v-for="editor of editorStack" :key="editor.level"
					:class="{ editor: true, ['level' + editor.level]: true }">
					<EntryEditor ref="entryEditor"
						v-model="editor.entry" 
						:contentType="editor.contentType" 
						@close="editorPop($event)"
						@subedit="editorPush($event)"
						@subcreate="editorPushNew($event)"
					></EntryEditor>
				</div>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import MediaCard from '@/components/common/MediaCard.vue'
import FileUpload from '@/components/common/FileUpload'
import Common from '@/mixins/Common.vue'
import LinksField from '@/components/entry/LinksField.vue'
import LinkField from '@/components/entry/LinkField.vue'
import EntryEditor from '@/components/entry/EntryEditor.vue'
import EntryList from '@/components/entry/EntryList.vue'
import Dialog from '@/components/common/Dialog.vue'
import AuditLog from '@/components/auditLog/AuditLog.vue'
import { Action } from '@/plugins/enum.js'
import _ from 'lodash'
import { Client } from "@/models/client.ts"
import ClientStatus from './ClientStatus.vue'

export default {
	name: "ClientDetailView",
	components: { Loading, SideBar, Alert, Disclosure, MediaCard, FileUpload, LinksField, LinkField, EntryEditor, EntryList, Dialog, AuditLog, ClientStatus },
	mixins: [ Common ],

	props: {
		initialAction: { type: String }
	},

	data() {
		return {
			Action: Action,
			action: this.initialAction,

			editorStack: [],
			navbarValue: '',

			salesChannels: [],
			resellerCategories: [],
			taxClasses: [],
			ticketTypes: [],
			reductions: [],
			defaultAssignments: [],
			productCategories: [],
			coreConfig: {},
			clientFeatures: [],
			marketplaceSettings: [],
			regions: [],
			
			originalBackgroundImage: {},
			isUpdateBackgroundImage: false,
			newBackgroundImage: {},
			backgroundImageDropOptions: {
				acceptedFiles: '.png,.jpg,.jpeg',
				addRemoveLinks: true,
				maxFilesize: 10,
				maxFiles: 1,
			},
			sectionMessage: {
				generalInfo: {error:false,warning:false,message:''},
				features:  {error:false,warning:false,message:''},
				peakConfig: {error:false,warning:false,message:''},
				contentfulConfig: {error:false,warning:false,message:''},
				contingentServiceConfig: {error:false,warning:false,message:''},
				ticketServiceConfig: {error:false,warning:false,message:''},
				jamesConfig: {error:false,warning:false,message:''},
				productCategories: {error:false,warning:false,message:''},
				salesChannels: {error:false,warning:false,message:''},
				resellerCategories: {error:false,warning:false,message:''},
				taxClasses: {error:false,warning:false,message:''},
				ticketTypes: {error:false,warning:false,message:''},
				reductions: {error:false,warning:false,message:''},
				defaultAssignments: {error:false,warning:false,message:''},
				contactTracing:  {error:false,warning:false,message:''}
			},

			client: new Client(),
			clientId: '',
			salesChannelFilter: { contentType: 'salesChannel', search: '' },
			productCategoryFilter: { contentType: 'productCategory', search: '' },
			taxClassFilter: { contentType: 'taxClass', search: '' },
			ticketTypeFilter: { contentType: 'ticketType', search: '' },
			reductionFilter: { contentType: 'reduction', search: '' },
			regionsFilter: { contentType: 'region', search: '' },
		}
	},
	
	computed: {
		clientNameError() {
			return this.runValidation && !this.client.fields.title.de.length ? 'Client Name is required ' : ''
		},
		clientIdError() {
			return this.runValidation && !this.client.fields.clientId.de.length ? 'Client ID is required' : ''
		},
		shopClientIdError() {
			return this.runValidation && !this.client.fields.shopClientId.de.length ? 'Shop Client ID is required' : ''
		},
		shopUrlError() {
			return this.runValidation && !this.client.fields.shopUrl.de.length ? 'Shop URL is required' : ''
		},
		currencyError() {
			return this.runValidation && !this.client.fields.currency.de.length ? 'Currency is required' : ''
		},
		contingentServiceUrlError() {
			return this.runValidation && !this.client.fields.contingentServiceUrl.de.length ? 'Contingent Service URL is required' : ''
		},
		contingentServiceUserError() {
			return this.runValidation && !this.client.fields.contingentServiceUser.de.length ? 'Contingent Service User is required' : ''
		},
		contingentServicePasswordError() {
			return this.runValidation && !this.client.fields.contingentServicePassword.de.length ? 'Contingent Service Password is required' : ''
		},
		installationIdError() {
			return this.runValidation && !this.client.fields.installationId.de.length ? 'Installation Id (SKISUP) is required' : ''
		},
		editorTitle() {
			return this.editorStack?.[this.editorStack?.length-1]?.contentType?.name
		} 
	},

	async mounted() {
		if (!this.schema) {
			await this.getSchema()
		}
		await this.getClientDetails()
	},

	methods: {
		async getClientDetails() {
			if (!this.$store.state.selectedClient?.sys?.id?.length) return this.setNewClientDetails()
			
			this.client = _.merge(this.client, this.$store.state.selectedClient)
			
			this.setReferenceFields()

			this.coreConfig = await this.$httpGet(`/core-config`)

			this.clientFeatures = []
			if (this.coreConfig.fields.features?.de?.length) {
				// Initialize features array if it doesn't exist
				if (!this.client.fields.features?.de) {
					this.client.fields.features = { de: [] }
				}

				for (const feature of this.coreConfig.fields.features.de) {
					const clientFeature = this.client.fields.features.de.find(x => x?.id === feature)

					// Add missing feature as disabled
					if (!clientFeature) {
						this.client.fields.features.de.push({
							id: feature,
							status: 'disabled'
						})
					}

					this.clientFeatures.push({
						id: feature,
						selected: clientFeature?.status === 'enabled'
					})
				}
			}
			
			this.marketplaceSettings = []
			if (this.coreConfig.fields.marketplaceSettings?.de?.length) {
				for (const setting of this.coreConfig.fields.marketplaceSettings.de) {
					const marketplaceSetting = this.client.fields.marketplaceSettings?.de?.find(x => x?.id === setting)

					if (!marketplaceSetting) {
						this.marketplaceSettings.push({
							id: setting,
							enabled: false
						})
					} else {
						this.marketplaceSettings.push({
							id: setting,
							enabled: marketplaceSetting?.enabled
						})
					}
				}
			}

			this.clientId = this.client.sys.id

			this.validateClientDetails()
		},
		async setNewClientDetails() {
			this.coreConfig = await this.$httpGet(`/core-config`)

			this.clientFeatures = []
			this.marketplaceSettings = []
			this.clientId = this.client.sys.id

			this.setClientFeatures()
			this.setMarketplaceSettings()
			this.setProductCategories()
			this.setReferenceFields()
		},
		setClientFeatures() {
			this.client.fields.features = {de:[]}
			if (this.coreConfig.fields.features?.de?.length) {
				for (const feature of this.coreConfig.fields.features.de) {
					this.clientFeatures.push({
						id: feature,
						selected: false
					})

					this.client.fields.features.de.push({
						id: feature,
						status: "disabled"
					})
				}
			}
		},
		setMarketplaceSettings() {
			this.client.fields.marketplaceSettings = {de:[]}

			if (this.coreConfig.fields.marketplaceSettings?.de?.length) {
				for (const setting of this.coreConfig.fields.marketplaceSettings.de) {
					this.marketplaceSettings.push({
						id: setting,
						enabled: false
					})

					this.client.fields.marketplaceSettings.de.push({
							id: setting,
							enabled: false
						})
				}
			}
		},
		setProductCategories() {
			for (let productCategory of this.coreConfig.fields.productCategories.de) {
				productCategory.sys.id = ''
			}
			this.client.fields.productCategories = {de: this.coreConfig.fields.productCategories.de}
		},
		setReferenceFields() {
			this.salesChannels = this.client?.fields?.salesChannels?.de
			this.salesChannels?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

			this.resellerCategories = this.client?.fields?.resellerCategories?.de
			this.resellerCategories?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

			this.taxClasses = this.client?.fields?.taxClasses?.de
			this.taxClasses?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

			this.ticketTypes = this.client?.fields?.ticketTypes?.de
			this.ticketTypes?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

			this.reductions = this.client?.fields?.reductions?.de
			this.reductions?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

			this.defaultAssignments = this.client?.fields?.defaultAssignments?.de

			this.productCategories = this.client?.fields?.productCategories?.de
			this.productCategories?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

			this.originalBackgroundImage = this.client?.fields?.backgroundImage?.de ? JSON.parse(JSON.stringify(this.client.fields.backgroundImage.de)) : {}
		},
		async upsertClient() {
			const isValid = this.validateClientDetails()
			if (!isValid) return

			this.loading = true
			
			try {
				if (Object.keys(this.newBackgroundImage).length > 0) {
					this.client.fields.backgroundImage.de = this.newBackgroundImage
				}

				this.client.fields.title.en = this.client.fields.title.de
				this.client.fields.title.fr = this.client.fields.title.de
				this.client.fields.title.it = this.client.fields.title.de

				//Set selected features
				for (const feature of this.clientFeatures) {
					let cFeature = this.client.fields.features.de.find(clientFeature => clientFeature.id === feature.id)
					if (cFeature?.id) {
						cFeature.status = feature.selected === true ? 'enabled' : 'disabled'
					}
					else {
						this.client.fields.features.de.push({
							id: feature.id,
							status: 'disabled',
						})
					}
				}

				//Set selected marketplace settings
				for (const setting of this.marketplaceSettings) {
					let cSetting = this.client.fields.marketplaceSettings.de.find(clientSetting => clientSetting.id === setting.id)
					if (cSetting?.id) {
						cSetting.enabled = setting.enabled
					}
					else {
						this.client.fields.features.de.push({
							id: setting.id,
							enabled: setting.enabled
						})
					}
				}
				
				const data = {
					client: this.client,
				}

				let client
				
				if (this.client.sys.id === '') {
					client = await this.$httpPost(`/client`, data)
				}
				else {
					client = await this.$httpPut(`/client`, data)
				}
				
				await this.$store.commit('setSelectedClient', client)
				await this.getClientDetails()

				this.successTitle = 'UPDATE CLIENT'
				this.successDetail = 'Client updated successfully'

			}
			catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		showUpdateBackgroundImage() {
			this.isUpdateBackgroundImage = true
		},
		onThumbnailCreated(file) {
			this.newBackgroundImage = {
				sys: {
					id: file.upload.uuid,
				},
				fields:{
					title: {},
					altText: {},
					description: {},
					file: file,
				}
			}

			for (const locale of this.locales) {
				this.newBackgroundImage.fields.title[locale.code] = `MYS Background - ${this.client.fields.title.de}`
				this.newBackgroundImage.fields.altText[locale.code] = `MYS Background- ${this.client.fields.title.de}`
				this.newBackgroundImage.fields.description[locale.code] = `MYS Background - ${this.client.fields.title.de}`
			}
		},
		cancelUpload() {
			this.newBackgroundImage = {}
			this.isUpdateBackgroundImage = false
			this.client.fields.backgroundImage.de = JSON.parse(JSON.stringify(this.originalBackgroundImage))
		},
		entrySelected(entries) {
			this.editorPush(entries[0])
		},
		editorPush(entry, callback) {
			this.editorStack.push({
				level: this.editorStack.length,
				entry: entry,
				callback: callback,
				// TODO: check if schema could not be found -> error
				contentType: this.schema.contentTypes[entry.sys.contentType.sys.id],
			})

			this.$refs.entryEditorDialog.show = true
		},
		editorPop() {
			this.editorStack.pop()
			this.$refs.entryEditorDialog.show = true
		},
		editorPushNew(command) {
			let contentType = this.schema.contentTypes[command.contentType]
			let entry = {
				isNew: true,
				sys: {
					id: 'MYS-CLM-' + new Date().getTime() + '-' + Math.floor(Math.random() * 1000),
					contentType: {
						sys: { id: command.contentType }
					}
				},
				fields: {}
			}
			for (const field of contentType.fields) {
				let p = undefined
				if (field.type === 'String' && field.localized == false) p = { de: '' }
				if (field.type === 'Number' && field.localized == false) p = { de: '' }
				// TODO: for localizable fields: go through locales
				if (field.type === 'I18nString') p = { de: '', en: '', fr: '', it: '', nl: '' }
				if (field.type === 'Array' && field.items.type === 'String') p = { de: [] }
				if (field.type === 'Array' && field.items.type === 'Link') p = { de: [] }
				if (field.type === 'Boolean') p = { de: false }
				if (field.type === 'Link' && field.linkType === 'Entry') p = { de: null }
				if (field.type === 'Object') p = { de: {} }
				// TODO: asset links
				entry.fields[field.id] = p
			}
			this.editorPush(entry, command.callback)
		},
		async confirmHandler() {
			let entry = this.$refs.entryEditor[this.$refs.entryEditor.length-1].sendData()
			const res = await this.$httpPost(`/content/entry`, entry)

			if (this.$refs.entryEditor.length > 1) {
				const contentType = this.$refs.entryEditor[this.$refs.entryEditor.length-2].contentType.linkContentType
				entry = this.$refs.entryEditor[this.$refs.entryEditor.length-2].entry
				this.addLinkedReferenceToParent(contentType, entry, res.sys.contentType.sys.id, res)
				this.editorPop()
				return false
			}
			else {
				this.addLinkedReferenceToParent('client', this.client, res.sys.contentType.sys.id, res)
				this.setReferenceFields()
			}

			return true
		},
		addLinkedReferenceToParent(parentName, parentObj, contentType, entry) {
			parentName = parentName ?? parentObj?.sys?.contentType?.sys?.id
			console.log('addLinkedReferenceToParent', parentName, parentObj, contentType, entry, 'schema', this.schema)
			const parentType = this.schema.contentTypes[parentName]
			console.log('addLinkedReferenceToParent', parentName, parentObj, 'PT', parentType, 'CT', contentType, entry, 'schema', this.schema)
			let parentField = parentType.fields.find(field => field.items?.linkContentType?.find(linkedField => linkedField === contentType))

			if (!parentField) (
				//Entry is NOT linked to the client as an array reference link, it is a single reference
				parentField = parentType.fields.find(field => field.id === entry.sys.contentType.sys.id)
			)

			if (!parentField) return

			if (parentField.type === "Array") {
				if (!parentObj['fields'][parentField.id].de.length) {
					parentObj['fields'][parentField.id].de.push(entry)
					return
				}

				let isUpdate = false
				for (let existingEntry of parentObj['fields'][parentField.id].de) {
					if (existingEntry.sys.id === entry.sys.id) {
						existingEntry = entry
						isUpdate = true
					}
				}

				if (!isUpdate) {
					parentObj['fields'][parentField.id].de.push(entry)
				}
			} else {
				parentObj['fields'][parentField.id] = { de: entry }
			}
		},
		cancelHandler() {
			this.editorStack = []
			this.$refs.entryEditorDialog.show = false
		},
		editorBackHandler() {
			this.editorStack.pop()
		},
		goback() {
			this.$router.push('/clients')
		},
		validateClientDetails() {
			this.runValidation = true
			let isValid = true
			
			const isValidGeneralInfo = this.validateGeneralInfo()
			const isValidPeakConfig = this.validatePeakConfig()
			const isValidContingent = this.validateContingentConfig()
			const isValidSalesChannels = this.validateSalesChannels()
			const isValidTaxClasses = this.validateTaxClasses()

			if (isValidGeneralInfo === false || 
					isValidPeakConfig === false || 
					isValidContingent === false || 
					isValidSalesChannels === false || 
					isValidTaxClasses === false) {
				
				this.errorTitle = 'ERROR'
				this.errorDetail = 'Please enter the required information'
				isValid = false
			}
			
			return isValid
		},
		validateGeneralInfo() {
			if (this.clientNameError.length || 
					this.clientIdError.length || 
					this.currencyError.length) {

				this.sectionMessage.generalInfo.error = true
				this.sectionMessage.generalInfo.message = 'Please enter the required information'
				return false
			} else {
				this.sectionMessage.generalInfo.error = false
				return true
			}
		},
		validatePeakConfig() {
			if (this.installationIdError.length || 
					this.shopClientIdError.length || 
					this.shopUrlError.length) {

				this.sectionMessage.peakConfig.error = true
				this.sectionMessage.peakConfig.message = 'Please enter the required information'
				return false
			} else {
				this.sectionMessage.peakConfig.error = false
				return true
			}
		},
		validateContingentConfig() {
			if (this.contingentServiceUrlError.length || 
					this.contingentServiceUserError.length || 
					this.contingentServicePasswordError.length) {

				this.sectionMessage.contingentServiceConfig.error = true
				this.sectionMessage.contingentServiceConfig.message = 'Please enter the required information'
				return false
			} else {
				this.sectionMessage.contingentServiceConfig.error = false
				return true
			}
		},
		validateSalesChannels() {
			if (!this.salesChannels.length) {
				this.sectionMessage.salesChannels.error = true
				this.sectionMessage.salesChannels.message = 'Please link at least one sales channel'
				return false
			} else {
				this.sectionMessage.salesChannels.error = false
				return true
			}
		},
		validateTaxClasses() {
			if (!this.taxClasses.length) {
				this.sectionMessage.taxClasses.error = true
				this.sectionMessage.taxClasses.message = 'Please link at least one tax class'
				return false
			} else {
				this.sectionMessage.taxClasses.error = false
				return true
			}
		}

	}
}
</script>

<style scoped>
.link-field { border: thin #dddddd solid; border-radius: 10px; margin-top: 10px; margin-bottom: 10px; }
.header { color: gray; font-size: smaller; text-transform: capitalize; }
.pickerCard { border-radius: 10px; border: 0; padding: 15px; }
.addEntry { border: 1px dashed #ccc; padding: 20px; border-radius: 5px; text-align: center; }
.editor {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  background-color: white;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 70px;
	margin-bottom: 70px;
}
</style>